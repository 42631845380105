import {reloadManagers, reloadTags, loadUserBunch} from 'app/store/helpers'
import BarChart from './charts/BarChart'
import IndividualPiesChart from './charts/IndividualPiesChart'
import {iterateObject} from 'app/lib/utils'

class StatisticExecutor extends Component {
  state = { forceTable: !!this.props.statistic.additional_data.force_table }

  componentDidMount() {
    reloadManagers();
    reloadTags();
    this.loadUsers();
  }

  componentDidUpdate() {
    this.loadUsers();
  }

  loadUsers() {
    const {statistic} = this.props;
    if(!statistic) {
      return
    }

    let usersToLoad = [];
    iterateObject(statistic.column_types, (c, t) => t == 'user_id' && usersToLoad.push(statistic.columnValues(c)));
    if(usersToLoad.length > 0) {
      usersToLoad = usersToLoad.reduce((a, e) => a.push(...e) && a, []); // flatten
      loadUserBunch(usersToLoad);
    }
  }

  render() {
    return (
      <div>
        <Checkbox label='Показать таблицей'
                         checked={this.state.forceTable}
                         onChange={v => this.setState({ forceTable: v })} />
        <Margin size='20' />
        {this.renderChartOrTable()}
      </div>
    )
  }

  renderChartOrTable() {
    const {forceTable} =  this.state;
    const {statistic} = this.props;

    if(!forceTable) {
      switch(statistic.chart_type) {
        case 'bars': return <BarChart statistic={statistic} />;
        case 'individual_pies': return <IndividualPiesChart statistic={statistic} />;
      }
    }

    return (
      <Table cols={statistic.orderedColumnHumanNames()}>
        {this.tableBody()}
      </Table>
    )
  }

  tableBody() {
    const {statistic} = this.props;
    const createTds = values => values.map((v, i) => <td key={i}>{v}</td>);

    return this.tableValues().map(createTds).map((tds, i) => <tr key={i}>{tds}</tr>);
  }

  tableValues() {
    const {statistic} = this.props;
    const orderedColumns = statistic.orderedColumnSystemNames();
    const rowValues = row => orderedColumns.map(col => this.rowValue(row, col));
    return statistic.data.map(rowValues);
  }

  rowValue(row, columnName) {
    const {statistic} = this.props;
    const columnTypes = statistic.column_types;
    const value = row[columnName];

    switch(columnTypes[columnName]) {
      case 'manager_id':
        return <ManagerById id={value} />;
      case 'tag_id':
        return <TagById id={value} />;
      case 'category_id':
        return row.parent_id
          ? <div>
              <CategoryById id={row.parent_id} main/>
              <CategoryById id={value} withMarginLeft />
            </div>
          : <CategoryById id={value} main/>
      case 'user_id':
        return <staff.LinkToUser id={value} target='_blank'><UserById id={value} doNotLoad /></staff.LinkToUser>;
      default:
        return value;
    }
  }
}

export default StatisticExecutor;
