import {bemModifiers} from 'app/lib/utils';

class RatingIcon extends Component {
  render() {
    const {onClick, uncolored} = this.props;

    const iconModifiers = [this.opinionType(), uncolored && 'uncolored', onClick && 'clickable'].filter(e => e);
    const iconClass = bemModifiers('rating-icon__icon', iconModifiers);

    const style = { fontSize: this.props.size || 25 }; // TODO: use modifiers with fixed sizes or font-size: inherit

    return (
      <div className='rating-icon' style={style}>
        <Icon name={this.iconName()} onClick={onClick} className={iconClass} />
      </div>
    );
  }

  iconName() {
    switch(this.opinionType()) {
      case 'positive':
        return 'sentiment_very_satisfied';
      case 'negative':
        return 'sentiment_very_dissatisfied';
      default:
        return 'sentiment_neutral';
    }
  }

  opinionType() {
    const {opinion} = this.props;
    if(opinion === 'good') {
      return 'positive';
    }

    if(opinion == 'bad') {
      return 'negative';
    }

    return opinion;
  }
}

export default RatingIcon;
